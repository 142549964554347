import React from 'react';

import {RenderEditCellProps} from 'react-data-grid';

import {
	ParagraphField,
	renderArrayField,
	renderDate,
	renderPhase,
} from 'components/EntityList/ColumnRenderers';

import {IRow} from './ParagraphRows';

import {GetParagraphsFormDataQuery} from 'features/RegulatoryDocuments/hooks/useGetParagraphsFormData.generated';

import {
	ParagraphFormFields,
	SelectableItemsByType,
} from '../DocumentDetails/EditParagraphsForm/EditParagraphsForm.types';

import {TFunction} from 'react-i18next';

import {Callout, Selection, Check, ActionButton} from '@fluentui/react';

import {
	ControlledDatePicker,
	ControlledTagPicker,
	PhasePicker,
} from 'components/hookForms';
import {Control} from 'react-hook-form';

interface IColumnsGridView {
	(
		fnCheckbox: () => ({row}: {row: IRow}) => JSX.Element,
		fnParagraphCell: () => ({row}: {row: IRow}) => JSX.Element,
		fnDataCell: (
			name: string,
			fieldType: string,
		) => (({row}: {row: IRow}) => JSX.Element) | undefined,
		fnEditDataCell: (
			name: string,
			tname: string,
			fieldType: string,
			colNumber: number,
			arrItems?: any[],
		) => ({row, onClose}: RenderEditCellProps<IRow>) => JSX.Element,
		t: TFunction,
	): any[];
}

export const getColumnsGridView: IColumnsGridView = (
	fnCheckbox,
	fnParagraphCell,
	fnDataCell,
	fnEditDataCell,
	t,
) => {
	const tOptions = {
		ns: 'features/regulatorydocuments',
		keyPrefix: 'ParagraphsList',
	};

	const columns = [
		{
			key: 'checkbox',
			name: '',
			renderCell: fnCheckbox(),
			minWidth: 40,
			maxWidth: 40,
			width: 40,
			editable: false,
			frozen: true,
		},
		{
			key: 'paragraph',
			name: t('Paragraph', tOptions),
			renderCell: fnParagraphCell(),
			minWidth: 400,
			maxWidth: 800,
			width: 800,
			editable: false,
			frozen: true,
		},
		{
			key: 'categories',
			name: t('Categories', tOptions),
			renderCell: fnDataCell('categories', 'array'),
			renderEditCell: fnEditDataCell('categories', 'Categories', 'array', 1),
			minWidth: 100,
			width: 200,
			resizable: true,
		},
		{
			key: 'keywords',
			name: t('Keywords', tOptions),
			renderCell: fnDataCell('keywords', 'array'),
			renderEditCell: fnEditDataCell('keywords', 'Keywords', 'array', 2),
			minWidth: 100,
			width: 200,
			resizable: true,
		},
		{
			key: 'tags',
			name: t('Tags', tOptions),
			minWidth: 100,
			width: 200,
			renderCell: fnDataCell('tags', 'array'),
			renderEditCell: fnEditDataCell('tags', 'Tags', 'array', 3),
			resizable: true,
		},
		{
			key: 'vehicleCategories',
			name: t('VehicleCategory', tOptions),
			minWidth: 100,
			width: 200,
			renderCell: fnDataCell('vehicleCategories', 'array'),
			renderEditCell: fnEditDataCell(
				'vehicleCategories',
				'VehicleCategory',
				'array',
				4,
			),
			resizable: true,
		},
		{
			key: 'driveVariants',
			name: t('DriveVariants', tOptions),
			minWidth: 100,
			width: 200,
			renderCell: fnDataCell('driveVariants', 'array'),
			renderEditCell: fnEditDataCell(
				'driveVariants',
				'DriveVariants',
				'array',
				5,
			),
			resizable: true,
		},
		{
			key: 'dateNewTypes',
			name: t('DateNewTypes', tOptions),
			minWidth: 100,
			width: 200,
			renderCell: fnDataCell('dateNewTypes', 'date'),
			renderEditCell: fnEditDataCell('dateNewTypes', 'DateNewTypes', 'date', 6),
			resizable: true,
		},
		{
			key: 'dateNewRegistration',
			name: t('DateNewRegistration', tOptions),
			minWidth: 100,
			width: 200,
			renderCell: fnDataCell('dateNewRegistration', 'date'),
			renderEditCell: fnEditDataCell(
				'dateNewRegistration',
				'DateNewRegistration',
				'date',
				7,
			),
			resizable: true,
		},
		{
			key: 'comprehensive',
			name: t('Comprehensive', tOptions),
			minWidth: 100,
			width: 200,
			renderCell: fnDataCell('comprehensive', 'date'),
			renderEditCell: fnEditDataCell(
				'comprehensive',
				'Comprehensive',
				'date',
				8,
			),
			resizable: true,
		},
		{
			key: 'modelYear',
			name: t('ModelYear', tOptions),
			minWidth: 100,
			width: 200,
			resizable: true,
		},
		{
			key: 'phaseIn',
			name: t('PhaseIn', tOptions),
			minWidth: 100,
			width: 200,
			renderCell: fnDataCell('phaseIn', 'phase'),
			renderEditCell: fnEditDataCell('phaseIn', 'PhaseIn', 'phase', 10),
			resizable: true,
		},
		{
			key: 'phaseOut',
			name: t('PhaseOut', tOptions),
			minWidth: 100,
			width: 200,
			renderCell: fnDataCell('phaseOut', 'phase'),
			renderEditCell: fnEditDataCell('phaseOut', 'PhaseOut', 'phase', 11),
			resizable: true,
		},
	];

	return columns;
};

export function renderCheckbox(
	selection: React.MutableRefObject<Selection<IRow>>,
) {
	const renderCell = ({row}: {row: IRow}) => {
		return (
			<div
				key={'chk' + row.rowId}
				style={{padding: '5px 8px'}}
				data-selection-index={row.rowId}
				onClick={e => {
					if (e.type === 'click') {
						const arrItemsSelected = selection.current.getSelection();
						if (e.shiftKey && arrItemsSelected.length > 0) {
							const intCurrent = row.rowId;
							const intTop = arrItemsSelected[0].rowId;
							const intBottom =
								arrItemsSelected[arrItemsSelected.length - 1].rowId;
							const intStart = intTop < intCurrent ? intTop : intCurrent;
							const intNumber =
								intTop < intCurrent
									? intCurrent - intTop + 1
									: intBottom - intCurrent + 1;
							selection.current.setRangeSelected(
								intStart,
								intNumber,
								true,
								false,
							);
						} else {
							selection.current.setIndexSelected(
								row.rowId,
								!selection.current.isIndexSelected(row.rowId),
								false,
							);
						}
					}
				}}
			>
				<Check checked={selection.current.isIndexSelected(row.rowId)} />
			</div>
		);
	};
	return renderCell;
}

export function renderParagraphCell(t: TFunction) {
	const renderCell = function ({row}: {row: IRow}) {
		return <ParagraphField item={row.paragraph} t={t} />;
	};
	return renderCell;
}

export function renderDataCell(name: string, fieldType: string) {
	if (fieldType === 'array') {
		return function ({row}: {row: IRow}) {
			return renderArrayField()(row.paragraph, 0, {
				key: name,
				name,
				fieldName: name,
				minWidth: 100,
			});
		};
	}
	if (fieldType === 'date') {
		return function ({row}: {row: IRow}) {
			return renderDate()(row.paragraph, 0, {
				key: name,
				name,
				fieldName: name,
				minWidth: 100,
			});
		};
	}
	if (fieldType === 'phase') {
		return function ({row}: {row: IRow}) {
			return renderPhase()(row.paragraph, 0, {
				key: name,
				name,
				fieldName: name,
				minWidth: 100,
			});
		};
	}
}

export const renderDataEditCell = (
	name: string,
	tname: string,
	fieldType: string,
	colNumber: number,
	handleDismiss: (name: string, row: IRow) => () => void,
	control: Control<ParagraphFormFields, any>,
	t: TFunction,
	arrItems: any[],
) => {
	const renderEditCell = ({row, onClose}: RenderEditCellProps<IRow>) => {
		return (
			<>
				{renderDataCell(name, fieldType)!({row})}
				<Callout
					alignTargetEdge={true}
					role='dialog'
					gapSpace={0}
					target={`[role="row"][aria-rowindex="${
						row.rowId + 2
					}"] > [role="gridcell"][aria-colindex="${colNumber + 2}"]`}
					setInitialFocus
					calloutMinWidth={500}
					calloutWidth={500}
					calloutMaxWidth={500}
				>
					{fieldType === 'array' && (
						<ControlledTagPicker
							name={name}
							label={t(tname, {
								ns: 'features/regulatorydocuments',
								keyPrefix: 'ParagraphsList',
							})}
							control={control}
							selectableItems={arrItems!}
							getKey={item => item.id}
							getName={item => item.name}
						/>
					)}
					{fieldType === 'date' && (
						<ControlledDatePicker
							control={control}
							name={name}
							label={t(tname, {
								ns: 'features/regulatorydocuments',
								keyPrefix: 'ParagraphsList',
							})}
						/>
					)}
					{fieldType === 'phase' && (
						<PhasePicker
							control={control}
							label={t(tname, {
								ns: 'features/regulatorydocuments',
								keyPrefix: 'ParagraphsList',
							})}
							name={name}
						/>
					)}
					<ActionButton onClick={handleDismiss(name, row)}>
						{'Änderungen vornehmen'}
					</ActionButton>
				</Callout>
			</>
		);
	};
	return renderEditCell;
};

export const mapFormEdgeNodes = (
	data?: GetParagraphsFormDataQuery,
): SelectableItemsByType => ({
	mainKeywordData: data?.keywords ?? [],
	vehicleCategorieData: data?.vehicleCategories ?? [],
	categoriesData: data?.categories ?? [],
	driveVariantData: data?.driveVariants ?? [],
	keywordData: data?.keywords ?? [],
	tagData: data?.tags ?? [],
});
