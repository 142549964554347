import {
	QueryResult,
	RefetchQueriesInclude,
	useApolloClient,
} from '@apollo/client';
import {
	DefaultButton,
	Panel,
	PanelType,
	PrimaryButton,
	Separator,
	Toggle,
} from '@fluentui/react';
import {ConfirmDeleteDialog, DialogState, HideDialog} from 'components/Dialogs';
import {LoadWrapper} from 'components/LoadWrapper';
import {MarketNamingTemplateField} from 'components';
import {ControlledTagPicker, ControlledTextField} from 'components/hookForms';
import {ControlledRichtextEditor} from 'components/hookForms/ControlledRichtextEditor';
import {useGetConvolutesQuery} from 'features/AdminSection/hooks/convolutes.generated';
import {useGetGdprRuleQuery} from 'features/AdminSection/hooks/gdprRules.generated';
import {
	LabelWithTooltip,
	ProviderThatEnablesGettingTooltipsFromContext,
} from 'features/localizedTooltips';
import {mapToRef} from 'helpers';
import {useFileUpload} from 'hooks';
import React from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {ConvoluteType, Regulation} from 'types';
import {RegulationsContextProps} from '../context';
import {
	GetRegulationFormDataQuery,
	GetRegulationQuery,
	useCreateRegulationMutation,
	useDeleteRegulationMutation,
	useGetRegulationFormDataQuery,
	useUpdateRegulationMutation,
} from '../hooks';
import {useUpdateRegulationAttachmentsMutation} from '../hooks/updateRegulationAttachments.generated';
import {RegulationsTooltipTranslationProvider} from './RegulationsTooltipTranslationProvider';
import {
	RegulationFormMode,
	SetRegulationFormMode,
} from './useRegulationFormMode';
import {useRegulationFormTranslation} from './useRegulationFormTranslation';

export interface RegulationFormProps {
	dialogState: DialogState;
	hideDialog: HideDialog;
	setMode: SetRegulationFormMode;
	mode: RegulationFormMode;
	startPolling: QueryResult['startPolling'] | null;
	/**
	 * Whether we should start polling when the dialog closes. This only applies
	 * if "startPolling" was provided.
	 */
	shouldStartPolling?: boolean;
	regulation?:
		| RegulationsContextProps['selectedRegulation']
		| GetRegulationQuery['regulation'];
	queriesToRefetchAfterSaveOrDelete: RefetchQueriesInclude;
}

export const RegulationForm = ({
	dialogState,
	hideDialog,
	setMode,
	mode,
	startPolling,
	regulation,
	queriesToRefetchAfterSaveOrDelete,
	shouldStartPolling = true,
}: RegulationFormProps) => {
	const {t} = useRegulationFormTranslation();
	const [createRegulation] = useCreateRegulationMutation();
	const [updateRegulation] = useUpdateRegulationMutation();
	const [deleteRegulation] = useDeleteRegulationMutation();
	const [updateRegulationAttachments] =
		useUpdateRegulationAttachmentsMutation();
	const [showAttachments, setShowAttachments] = React.useState(false);

	const {data: convolutes} = useGetConvolutesQuery();

	const convolute = React.useMemo(
		() =>
			convolutes?.convolutes?.find(
				c => c.convoluteType === ConvoluteType.Regulation,
			),
		[convolutes],
	);

	const {data: gdprRule} = useGetGdprRuleQuery({
		variables: {
			id: convolute?.gdprRule?.id || '',
		},
	});

	const rule = React.useMemo(() => gdprRule?.gdprRule, [gdprRule]);

	const navigate = useNavigate();

	const apolloClient = useApolloClient();

	const handleDeleteClick = React.useCallback(() => {
		if (regulation?.id) {
			deleteRegulation({
				variables: {
					input: {
						id: regulation.id,
					},
				},
				refetchQueries: queriesToRefetchAfterSaveOrDelete,
			});
		}

		hideDialog();
	}, [regulation, hideDialog]);

	const {handleSubmit, control, reset, watch} = useForm<Regulation>({
		reValidateMode: 'onSubmit',
		mode: 'onBlur',
	});

	const selectedMarkets = watch('markets') ?? [];

	React.useEffect(() => {
		reset(regulation ?? {});
	}, [regulation]);

	const {attachments, FileUploadComponent} = useFileUpload(
		(regulation ?? undefined) as any,
		{
			enableVkoOnlyOption: true,
		},
	);

	const onRegulationSaved = React.useCallback(
		async (regId?: string) => {
			if (regId && attachments) {
				await updateRegulationAttachments({
					variables: {
						input: {
							regulationId: regId,
							attachments: attachments?.map(f => ({
								attachmentId: f.file.name,
								file: f.file,
								categoryId: f.attachmentCategoryId,
								isVkoOnly: f.isVkoOnly,
							})),
						},
					},
				});
			}

			await apolloClient.refetchQueries({
				include: queriesToRefetchAfterSaveOrDelete,
			});
		},
		[attachments, apolloClient],
	);

	const closeAndResumePolling = React.useCallback(() => {
		setMode(RegulationFormMode.None);
		/**
		 * We must start polling because polling is paused whenever we open the form.
		 */
		if (shouldStartPolling) startPolling?.(10000);
	}, [startPolling, shouldStartPolling]);

	const handleSaveClick = React.useCallback(() => {
		handleSubmit(async input => {
			switch (mode) {
				case RegulationFormMode.Create:
					createRegulation({
						variables: {
							input: {
								regulationNumber: input.regulationNumber,
								name: input.name,
								mainKeywordRefs: mapToRef(input?.mainKeywords),
								marketRefs: mapToRef(input?.markets),
								standardPlusPcmsClusterRefs: mapToRef(
									input?.standardPlusPcmsClusters,
								),
								summary: input.summary,
								willBeAnonymized: rule?.anonymize || false,
								deletionPeriod: rule?.deletionPeriod || 0,
							},
						},
					}).then(res => {
						const id = res.data?.createRegulation.regulation.id;
						if (id) {
							onRegulationSaved(id);
							navigate(`/regulations/${id}`);
						}
					});
					break;
				case RegulationFormMode.Update:
					if (!regulation?.id) {
						break;
					}

					await updateRegulation({
						variables: {
							input: {
								regulationNumber: input.regulationNumber,
								name: input.name,
								id: regulation.id,
								mainKeywordRefs: mapToRef(input?.mainKeywords),
								marketRefs: mapToRef(input?.markets),
								standardPlusPcmsClusterRefs: mapToRef(
									input?.standardPlusPcmsClusters,
								),
								summary: input.summary,
							},
						},
					}).then(async res => {
						const id = res.data?.updateRegulation.regulation.id;
						if (id) {
							await onRegulationSaved(id);
						}
					});
					break;
				default:
					break;
			}

			closeAndResumePolling();
		})();
	}, [mode, attachments, navigate, rule]);

	const {data, loading} = useGetRegulationFormDataQuery();
	const {marketData, mainKeywordData, standardPlusPcmsClusterData} =
		React.useMemo(() => mapFormEdgeNodes(data), [data]);

	const RegulationDetails =
		mode === RegulationFormMode.Update
			? t('EditRegulation')
			: t('EditEntity', {selectedRegulationName: regulation?.name});

	const onRenderFooterContent: any = () => (
		<form>
			<PrimaryButton styles={buttonStyles} onClick={handleSaveClick}>
				{mode === RegulationFormMode.Create
					? t('CreateButton')
					: t('UpdateButton')}
			</PrimaryButton>
			<DefaultButton onClick={closeAndResumePolling}>
				{t('CancelButton')}
			</DefaultButton>
		</form>
	);

	return (
		<>
			<Panel
				isLightDismiss
				isOpen={mode !== RegulationFormMode.None}
				onDismiss={closeAndResumePolling}
				closeButtonAriaLabel='Close'
				headerText={
					mode === RegulationFormMode.Create
						? t('CreateRegulationHeader')
						: `${RegulationDetails}`
				}
				type={PanelType.medium}
				onRenderFooterContent={onRenderFooterContent}
				isFooterAtBottom={true}
			>
				<LoadWrapper loading={loading}>
					<RegulationsTooltipTranslationProvider>
						<ProviderThatEnablesGettingTooltipsFromContext>
							<ControlledTagPicker
								control={control}
								name={'markets'}
								label={t('MarketsTagPickerLabel')}
								selectableItems={marketData}
								getKey={item => item.id}
								getName={item => item.name}
								rules={{required: t('MarketFieldRequired')}}
							/>
							<MarketNamingTemplateField
								markets={selectedMarkets}
								namingHint={t('RegulationNumberNamingHint')}
								templatesToUse='regulationNamingTemplates'
							>
								{({validateFieldValue, isMarketSelected, placeholder}) => (
									<ControlledTextField
										required={true}
										label={t('RegulationNumberFieldLabel')}
										control={control}
										name={'regulationNumber'}
										rules={{
											required: t('RegulationNumberFieldRequired'),
											validate: validateFieldValue,
										}}
										disabled={!isMarketSelected}
										placeholder={placeholder}
									/>
								)}
							</MarketNamingTemplateField>
							<ControlledTextField
								required={true}
								label={t('NameFieldLabel')}
								control={control}
								name={'name'}
								rules={{required: t('NameFieldRequired')}}
							/>
							<ControlledRichtextEditor
								control={control}
								name={'summary'}
								label={t('SummaryFieldLabel')}
								rules={{required: t('SummaryFieldRequired')}}
							/>
							<ControlledTagPicker
								control={control}
								name={'standardPlusPcmsClusters'}
								label={t('StandardPlusPcmsClustersTagPickerLabel')}
								selectableItems={standardPlusPcmsClusterData}
								getKey={item => item.id}
								getName={item => item.name}
								rules={{required: t('StandardPlusPcmsClusterFieldRequired')}}
							/>
							<ControlledTagPicker
								control={control}
								name={'mainKeywords'}
								label={t('MainKeywordsTagPickerLabel')}
								selectableItems={mainKeywordData}
								getKey={item => item.id}
								getName={item => item.name}
								rules={{required: t('MainKeywordFieldRequired')}}
							/>
							<Separator />
							<LabelWithTooltip translationKey='attachments'>
								{t('AttachmentsFieldLabel')}
							</LabelWithTooltip>
							<Toggle onChange={() => setShowAttachments(!showAttachments)} />
							{showAttachments ? <FileUploadComponent /> : <div></div>}
						</ProviderThatEnablesGettingTooltipsFromContext>
					</RegulationsTooltipTranslationProvider>
				</LoadWrapper>
			</Panel>
			<ConfirmDeleteDialog {...dialogState} onConfirm={handleDeleteClick} />
		</>
	);
};

const mapFormEdgeNodes = (data?: GetRegulationFormDataQuery) => {
	return {
		marketData: splitSort(data?.markets || []),
		mainKeywordData: splitSort(data?.keywords || []),
		standardPlusPcmsClusterData: data?.standardPlusPcmsClusters || [],
		vehicleCategorieData: data?.vehicleCategories || [],
		driveVariantData: data?.driveVariants || [],
	};
};

const splitSort = (arrObjects: any[]) => {
	const maxID = 100000;
	const objectsUnsorted = arrObjects ?? [];
	const objectsSorted = objectsUnsorted
		.slice()
		.sort((a, b) => (a.sortPriority ?? maxID) - (b.sortPriority ?? maxID));
	return objectsSorted;
};

const buttonStyles = {
	root: {
		marginRight: 8,
	},
};
