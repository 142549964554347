import {IDialogContentProps, PersonaSize, Separator} from '@fluentui/react';
import {EntityListColumn, EntityPage} from 'components';
import {useDialogState} from 'components/Dialogs';
import {BaseDialog} from 'components/Dialogs/BaseDialog';
import {
	renderArrayField,
	renderPersonas,
} from 'components/EntityList/ColumnRenderers';
import {LoadWrapper} from 'components/LoadWrapper';
import {ViewAuthorizer} from 'components/ViewAuthorizer';
import {ControlledTagPicker, ControlledTextField} from 'components/hookForms';
import {ControlledPeoplePicker} from 'components/hookForms/ControlledPeoplePicker';
import {
	KeywordsTooltipTranslationProvider,
	ProviderThatEnablesGettingTooltipsFromContext,
} from 'features/localizedTooltips';
import {mapToRef} from 'helpers';
import React from 'react';
import {Control} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Keyword, UserRole, VexCluster} from 'types';
import {
	GetAllKeywordsDocument,
	useCreateKeywordMutation,
	useDeleteKeywordMutation,
	useGetAllKeywordsQuery,
	useGetRegulationClustersFormDataQuery,
	useUpdateKeywordMutation,
} from './hooks/keywords.generated';
import {
	useGetAllVexClustersQuery,
	useSyncNewVexClustersMutation,
} from './hooks/vexClusters.generated';

const AdminKeywordsPage: React.FC = () => {
	const {t} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminKeywordsPage',
	});

	const {loading, data} = useGetAllKeywordsQuery();
	const {loading: regulationClustersLoading, data: regulationClustersData} =
		useGetRegulationClustersFormDataQuery();
	const {loading: vexClutersLoading, data: vexClustersData} =
		useGetAllVexClustersQuery();
	const [createKeywordMutation] = useCreateKeywordMutation();
	const [updateKeywordMutation] = useUpdateKeywordMutation();
	const [deleteKeywordMutation] = useDeleteKeywordMutation();
	const [syncNewVexClusters] = useSyncNewVexClustersMutation();

	const keywords = React.useMemo(() => {
		const maxID = 100000;
		const keywordsUnsorted = data?.keywords ?? [];
		const keywordSorted = keywordsUnsorted
			.slice()
			.sort((a, b) => (a.sortPriority ?? maxID) - (b.sortPriority ?? maxID));
		return keywordSorted;
	}, [data]);

	const regulationClusters = React.useMemo(
		() => regulationClustersData?.regulationClusters ?? [],
		[regulationClustersData],
	);

	const vexClusters = React.useMemo(
		() => vexClustersData?.vexClusters ?? [],
		[vexClustersData],
	);

	const refetchQueries = [GetAllKeywordsDocument];

	const [
		{hidden, onDismiss},
		showSyncVexClustersDialog,
		hideSyncVexClustersDialog,
	] = useDialogState();
	const [vexClusterSyncState, setVexClusterSyncState] = React.useState<{
		vexClustersToSync: VexCluster[];
		keyword: Keyword | null;
	}>({vexClustersToSync: [], keyword: null});
	const syncVexClustersDialogContentProps: IDialogContentProps = React.useMemo(
		() => ({
			title: t('SyncVexClustersDialogTitle'),
			subText: t('SyncVexClustersDialogSubText', {
				newVexClusters: vexClusterSyncState.vexClustersToSync
					.map(vexCluster => `"${vexCluster.name}"`)
					.join(', '),
				keywordName: vexClusterSyncState.keyword?.name ?? '',
			}),
		}),
		[t, vexClusterSyncState.vexClustersToSync],
	);

	const createKeyword = React.useCallback((keyword: Keyword) => {
		createKeywordMutation({
			variables: {
				input: {
					name: keyword?.name,
					regulationClusterRefs: mapToRef(keyword?.regulationClusters),
					vexClusterRefs: mapToRef(keyword?.vexClusters),
					vkoRefs: mapToRef(keyword?.vkos),
					approverRefs: mapToRef(keyword?.approvers),
					sortPriority: parseInt(`${keyword.sortPriority}`, 10),
				},
			},
			refetchQueries,
		});
	}, []);

	const updateKeyword = React.useCallback(
		(keyword: Keyword, oldKeyword?: Keyword) => {
			updateKeywordMutation({
				variables: {
					input: {
						id: keyword.id,
						name: keyword?.name,
						regulationClusterRefs: mapToRef(keyword?.regulationClusters),
						vexClusterRefs: mapToRef(keyword?.vexClusters),
						vkoRefs: mapToRef(keyword?.vkos),
						approverRefs: mapToRef(keyword?.approvers),
						sortPriority: parseInt(`${keyword.sortPriority}`, 10),
					},
				},
				refetchQueries,
			}).then(() => {
				const newVexClusters = keyword.vexClusters.filter(
					vexCluster =>
						!oldKeyword?.vexClusters?.some(
							oldVexCluster => oldVexCluster.id === vexCluster.id,
						),
				);
				setVexClusterSyncState({
					vexClustersToSync: newVexClusters,
					keyword,
				});

				if (newVexClusters.length) {
					showSyncVexClustersDialog();
				}
			});
		},
		[showSyncVexClustersDialog, updateKeywordMutation, refetchQueries],
	);

	const deleteKeyword = React.useCallback((id: string) => {
		deleteKeywordMutation({
			variables: {
				input: {id},
			},
			refetchQueries,
		});
	}, []);

	const onConfirmVexClusterSync = React.useCallback(async () => {
		const {vexClustersToSync, keyword} = vexClusterSyncState;
		if (keyword) {
			await syncNewVexClusters({
				variables: {
					input: {
						keywordId: keyword?.id,
						newVexClusterIds: vexClustersToSync.map(
							vexCluster => vexCluster.id,
						),
					},
				},
			});
		}

		hideSyncVexClustersDialog();
	}, [hideSyncVexClustersDialog, vexClusterSyncState]);

	const columns: EntityListColumn[] = React.useMemo(
		() => [
			{
				key: 'regulationClusters',
				name: t('RegulationClusters'),
				fieldName: 'regulationClusters',
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				filterable: true,
				onRender: renderArrayField(),
			},
			{
				key: 'vkos',
				name: t('Vkos'),
				fieldName: 'vkos',
				minWidth: 200,
				maxWidth: 200,
				filterable: true,
				onRender: renderPersonas({size: PersonaSize.size8}),
			},
			{
				key: 'vexClusters',
				name: t('VexClusters'),
				fieldName: 'vexClusters',
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				filterable: true,
				onRender: renderArrayField(),
			},
			{
				key: 'shApprovers',
				name: t('SHApprovers'),
				fieldName: 'approvers',
				minWidth: 200,
				maxWidth: 200,
				onRender: renderPersonas({size: PersonaSize.size8}),
			},
			{
				key: 'sortPriority',
				name: t('sortPriority'),
				fieldName: 'sortPriority',
				minWidth: 200,
				isResizable: true,
				isMultiline: true,
				filterable: true,
			},
		],
		[t],
	);

	const formElements = (control: Control<Keyword>) => {
		return (
			<div>
				<ProviderThatEnablesGettingTooltipsFromContext>
					<ControlledTagPicker
						name={'regulationClusters'}
						label={t('RegulationClusterTagPickerLabel')}
						control={control}
						selectableItems={regulationClusters}
						getKey={item => item.id}
						getName={item => item.name}
					/>
					<ControlledTagPicker
						name={'vexClusters'}
						control={control}
						label={t('VexClustersTagPickerLabel')}
						selectableItems={vexClusters}
						getKey={item => item.id}
						getName={item => item.name}
					/>
					<ControlledPeoplePicker
						control={control}
						label={t('VkoPeoplePickerLabel')}
						name={'vkos'}
					/>
					<ControlledPeoplePicker
						control={control}
						label={t('SHApprovers')}
						name={'approvers'}
						roles={[UserRole.ShApprover]}
					/>
					<Separator />
					<ControlledTextField
						control={control}
						name={'sortPriority'}
						label={t('sortPriority')}
						type='number'
					/>
				</ProviderThatEnablesGettingTooltipsFromContext>
			</div>
		);
	};

	return (
		<ViewAuthorizer roles={[UserRole.SystemAdministrator]}>
			<LoadWrapper
				loading={loading || regulationClustersLoading || vexClutersLoading}
			>
				<KeywordsTooltipTranslationProvider>
					<EntityPage
						items={keywords as any}
						entityDisplayName={t('Keywords')}
						createEntity={createKeyword}
						updateEntity={updateKeyword}
						deleteEntity={deleteKeyword}
						additionalColumns={columns}
						renderAdditionalFormElements={control => formElements(control)}
					/>
				</KeywordsTooltipTranslationProvider>
				<BaseDialog
					styles={{main: {minWidth: 600}}}
					maxWidth={600}
					hidden={hidden}
					onConfirm={onConfirmVexClusterSync}
					onDismiss={onDismiss}
					confirmText={t('SyncVexClustersConfirmText')}
					cancelText={t('SyncVexClustersCancelText')}
					dialogContentProps={syncVexClustersDialogContentProps}
				/>
			</LoadWrapper>
		</ViewAuthorizer>
	);
};

export default AdminKeywordsPage;
